import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  appLogo: {
    height: 48,
  },
  background: {
    backgroundColor: '#fafafa',
  },
  backgroundAlt: {
    backgroundColor: '#333333',
  },
  backgroundAltActive: {
    backgroundColor: '#5bb84d !important',
  },
  backgroundAltActiveHover: {
    backgroundColor: '#4d8406',
  },
  backgroundPrimary: {
    backgroundColor: '#005776',
  },
  boldSm: {
    fontWeight: 500,
  },
  defaultText: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  flex: {
    display: 'flex',
  },
  flexCenter: {
    margin: '0 auto',
    display: 'flex',
    textAlign: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  foreground: {
    color: '#333333',
  },
  foregroundAlt: {
    color: '#f0f0f0 !important',
  },
  foregroundAltActive: {
    color: '#333333',
  },
  foregroundAltHover: {
    color: '#5bb84d',
  },
  foregroundPrimary: {
    color: '#005776',
  },
  hide: {
    display: 'none',
  },
  marginBottomSm: {
    marginBottom: 12,
  },
  marginBottomMd: {
    marginBottom: '24px !important',
  },
  marginBottomLg: {
    marginBottom: '48px !important',
  },
  marginLeftMd: {
    marginLeft: 24,
  },
  marginLeftLg: {
    marginLeft: 24,
  },
  marginRightMd: {
    marginRight: 24,
  },
  nested: {
    paddingLeft: 32,
  },
  paddingMd: {
    padding: '2em',
  },
  paddingRightXs: {
    paddingRight: 6,
  },
  paddingTopSm: {
    paddingTop: 12,
  },
  palette: {
    // via style guide (http://linuxweb1.morainepark.edu/styleguide/core/color-palette.html)
    primary: { main: '#005776' }, // dark blue
    secondary: { main: '#5bb84d' }, // green
  },
  pointer: {
    cursor: 'pointer',
  },
  select: {
    minWidth: 150,
  },
  tabs: {
    backgroundColor: '#f0f0f0',
    padding: '0 2em',
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
  },
  wrapper: {
    width: '100%',
  },
});

theme.typography.h1 = {
  fontSize: '1.6rem',
  fontWeight: 500,
  lineHeight: 1.6,
};

export default theme;
