// React && Redux
import React from 'react';
import { withRouter } from 'react-router';

// Material
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Third Party
import Select from 'react-select';

// Internal
import BreadcrumbsWrapper from '../atoms/Breadcrumbs';
import Spacer from '../atoms/Spacer';
import { getCurrentDateTime, getCurrentSchoolYear, getIcon, getOptionObject, insertItem, compare, updateObjectInArray } from '../atoms/Util';

// Config
import { runtimeConfig } from '../../config/master';

// Images
import logo from '../../../public/images/mptc-logo.svg';
import { render } from 'react-dom';
// import studentLogo from '../../../public/images/mptc-student-logo.svg';

// class styling
const styles = (theme) => ({
  actionGridItem: {
    // padding: '0 !important',
    ...theme.flex,
    alignItems: 'flex-end',
  },
  actionWrapper: theme.flex,
  appBar: {
    backgroundColor: '#f0f0f0 !important',
    borderBottom: '3px solid #5bb84d',
  },
  backgroundAltActive: theme.backgroundAltActive,
  clearButton: {
    // marginLeft: 36,
  },
  // defaultText: theme.defaultText,
  fab: {
    marginLeft: 36,
    '&:hover': {
      backgroundColor: '#83db76',
    },
  },
  fabDisabled: {
    backgroundColor: '#aaaaaa',
    cursor: 'default',
    marginLeft: 36,
    '&:hover': {
      backgroundColor: '#cccccc',
    },
  },
  flex: theme.flex,
  flexCenter: {
    ...theme.flex,
    alignItems: 'center',
  },
  flexRight: {
    ...theme.flex,
    alignItems: 'right',
  },
  // foregroundAltHover: theme.foregroundAltHover,
  gridItem: {
    paddingTop: '0 !important',
  },
  logo: {
    ...theme.appLogo,
    marginRight: 36,
  },
  marginLeftFilter: theme.marginLeftMd,
  marginRightMd: theme.marginRightMd,
  pointer: theme.pointer,
  select: {
    ...theme.select,
  },
  selectDisabled: {

  },
  selectWrapper: theme.flexGrow,
  wrapper: {
    backgroundColor: '#005776',
    color: '#f0f0f0',
    // display: 'flex',
    margin: '0 auto',
    minWidth: 360,
    padding: 24,
    width: '100%',
  },
});

class SearchFilter extends React.Component {

  constructor(props) {
    super(props);
    const { schoolYears } = props.options;
    const currentSchoolYear = getCurrentSchoolYear(schoolYears);

    const activeBackgroundColor = 'rgba(91,184,77,0.3)'; // #5bb84d
    const defaultColor = 'rgba(0, 0, 0, 0.87) !important'; // #000000
    const defaultPlaceholderColor = 'rgb(89, 89, 89)'; // #oob color for react-select
    const disabledBackgroundColor = 'rgb(170,170,170)'; // #aaa
    // const disabledBackgroundColorHover = 'rgba(170,170,170,0.7)'; // #aaa
    const disabledColor = 'rgba(0, 0, 0, 0.6) !important'; // #000000
    const focusedBackgroundColor = 'rgba(91,184,77,0.4)'; // #5bb84d
    const selectedBackgroundColor = 'rgb(91,184,77)'; // #5bb84d

    const selectStyles = {
      control: (styles, { isDisabled }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? disabledBackgroundColor
            : 'white',
          color: isDisabled
            ? disabledColor
            : defaultColor,
        };
      },
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? selectedBackgroundColor
              : isFocused
                ? focusedBackgroundColor
                : null,
          color: isDisabled
            ? disabledColor
            : defaultColor,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? defaultColor : activeBackgroundColor),
          },
        };
      },
      multiValueLabel: (styles, ) => ({
        ...styles,
        color: defaultColor,
        backgroundColor: focusedBackgroundColor,
        borderRadius: '2px 0 0 2px',
      }),
      multiValueRemove: (styles, ) => ({
        ...styles,
        color: defaultColor,
        backgroundColor: focusedBackgroundColor,
        borderRadius: '0 2px 2px 0',
      }),
      placeholder: (styles, {isDisabled}) => {
        return {
          ...styles,
          color: isDisabled
          ? disabledColor
          : defaultPlaceholderColor,
        };
      }
    };
    this.state = {
      schoolYear: [getOptionObject(currentSchoolYear, currentSchoolYear)],
      selectStyles,
    };
  }

  handleFilterClear = (filterName) => {
    let options = {};
    if (filterName) {
      options = {
        [filterName]: null,
      };
      switch (filterName) {
        case 'room':
          options.lab = null;
          options.deviceType = null;
          break;
        case 'lab':
          options.deviceType = null;
          break;
      }
    } else {
      options.campus = null;
      options.room = null;
      options.lab = null;
      options.deviceType = null;
    }
    this.setState({ ...options });
  }

  handleFilterChange = (name, optionName, option) => {
    const { onFilterChange } = this.props;
    onFilterChange(name, optionName, option);
    if (!option || !option.length) {
      this.handleFilterClear(name);
    } else {
      this.setState({ [name]: option, });
    }
  };

  handlePrintLabs = () => {
    const { assignments } = this.props;
    const { campus, deviceType, lab, room, schoolYear, softwares } = this.state;
    if (schoolYear && campus && room) {
      // we can safely print
      const filters = [
        { name: 'schoolYear', selected: schoolYear },
        { name: 'campus', selected: campus },
        { name: 'room', selected: room },
        { name: 'lab', selected: lab },
      ];
      const subpath = runtimeConfig.subpath || '';
      const printedAssignments = assignments.filter(a => {
        let includeAssignment = true;
        filters.forEach(f => {
          if (includeAssignment && f.selected) {
            includeAssignment = f.selected.filter(s => a[f.name] === s.value).length;
          }
        });
        return includeAssignment;
      });
      const rooms = printedAssignments.map((a, idx) => (idx < printedAssignments.length - 1 ? `${a.room}&` : a.room));
      const labs = printedAssignments.map((a, idx) => (idx < printedAssignments.length - 1 ? `${a.lab}&` : a.lab));
      this.props.history.push(subpath.concat(`/lab/${rooms.toString().replace(/,/g, '')}/${labs.toString().replace(/,/g, '')}`), { assignments: printedAssignments });
    }
  };

  render() {
    const { classes, noSoftware = 0, onDialogClick, options = [] } = this.props;
    const { campuses = [], deviceTypes = [], labs = [], rooms = [], schoolYears = [], softwares = [] } = options;
    const { campus, deviceType, lab, room, schoolYear, selectStyles, } = this.state;
    return (
      <section className={classes.wrapper}>
        <Grid container className={classes.selectWrapper} spacing={3}>
          <Grid className={classes.gridItem} item xs={12} sm={4} lg={2}>
            <div className={classes.select}>
              <Typography
                className={classes.subtext}
                variant="caption"
                gutterBottom={true}>
                School Year
              </Typography>
              <Select
                aria-label="School Year"
                className={classes.select}
                isDisabled={false}
                isMulti={false}
                value={schoolYear}
                onChange={(e) => this.handleFilterChange('schoolYear', 'schoolYear', [e])}
                options={schoolYears}
                styles={selectStyles}
              />
            </div>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={4} lg={2}>
            <div className={classes.select}>
              <Typography
                className={classes.subtext}
                variant="caption"
                gutterBottom={true}>
                Campus
              </Typography>
              <Select
                aria-label="Campus"
                className={classes.select}
                isDisabled={schoolYear ? false : true}
                isMulti={false}
                value={campus}
                onChange={(e) => this.handleFilterChange('campus', 'room', [e])}
                options={campuses}
                styles={selectStyles}
              />
            </div>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={4} lg={2}>
            <div className={classes.select}>
              <Typography
                className={classes.subtext}
                variant="caption"
                gutterBottom={true}>
                Room
              </Typography>
              <Select
                aria-label="Room"
                className={classes.select}
                isDisabled={campus ? false : true}
                isMulti={true}
                value={room}
                onChange={(e) => this.handleFilterChange('room', 'lab', e)}
                placeholder={campus ? `Select...` : `Select Campus to Enable...`}
                options={rooms}
                styles={selectStyles}
              />
            </div>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={6} lg={3}>
            <div className={classes.select}>
              <Typography
                className={classes.subtext}
                variant="caption"
                gutterBottom={true}>
                Lab
              </Typography>
              <Select
                aria-label="Lab"
                className={classes.select}
                isDisabled={room ? false : true}
                isMulti={true}
                placeholder={room ? `Select...` : `Select Room to Enable...`}
                value={lab}
                onChange={(e) => this.handleFilterChange('lab', 'deviceType', e)}
                options={labs}
                styles={selectStyles}
              />
            </div>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={6} lg={3}>
            <div className={classes.select}>
              <Typography
                className={classes.subtext}
                variant="caption"
                gutterBottom={true}>
                Device Type
              </Typography>
              <Select
                aria-label="Device Type"
                className={classes.select}
                isDisabled={lab ? false : true}
                isMulti={true}
                placeholder={lab ? `Select...` : `Select Lab to Enable...`}
                value={deviceType}
                onChange={(e) => this.handleFilterChange('deviceType', 'deviceType', e)}
                options={deviceTypes}
                styles={selectStyles}
              />
            </div>
          </Grid>
        </Grid>
        <BreadcrumbsWrapper separator=">" aria="filter breadcrumb" filters={[schoolYear, campus, room, lab, deviceType]} variant="caption" />
        <Grid container className={classes.actionWrapper} spacing={3} alignContent="center" alignItems="center">
          <Grid className={classes.actionWrapper} item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="caption" className={classes.flexCenter}>No. Softwares: {noSoftware}</Typography>
          </Grid>
          <Grid className={classes.actionGridItem} item xs={12} sm={6} md={4} lg={7}>
            <Spacer />
            <Tooltip title="view standard software installed in every lab">
              <Button
                color="inherit"
                size="small"
                aria-label="view standard software list"
                className={classes.button}
                onClick={onDialogClick}
              >
                <Icon>view_list</Icon>View Standard Software</Button>
            </Tooltip>
          </Grid>
          <Grid className={classes.actionGridItem} item xs={12} sm={12} md={4} lg={3}>
            <Spacer />
            <Tooltip title="clear selected filters">
              <Button
                color="inherit"
                aria-label="clear filter"
                className={classes.clearButton}
                onClick={() => this.handleFilterChange()}
              >
                Clear
              </Button>
            </Tooltip>
            <Tooltip title={schoolYear && campus && room ? 'print a list of all of the labs matching the filter' : 'select a school year, campus, and room to enable'}>
              <Fab
                color="secondary"
                variant="extended"
                aria-label="print labs"
                size="medium"
                className={schoolYear && campus && room ? classes.fab : classes.fabDisabled}
                onClick={() => this.handlePrintLabs()}
              >
                <Icon>{schoolYear && campus && room ? 'print' : 'print_disabled'}</Icon>
                Print Labs
            </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default withStyles(styles)(withRouter(SearchFilter));
