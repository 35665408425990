const campusMap = [
  { label: "Beaver Dam", value: "BD" },
  { label: "Fond du Lac", value: "FDL" },
  { label: "Jackson Regional Center", value: "JRC" },
  { label: "West Bend", value: "WB" },
];

const deviceTypeMap = [
  { label: "Aquos Board-PC", value: "Aquos Board-PC" },
  { label: "Desktop", value: "Desktop" },
  { label: "iMac or MacPro", value: "iMac or MacPro" },
  { label: "iOS Device", value: "iOS Device" },
  { label: "Laptop", value: "Laptop" },
  { label: "Windows Tablet", value: "Windows Tablet" },
  { label: "Workstation-class Desktop", value: "Workstation-class Desktop" },
  { label: "Workstation-class Laptop", value: "Workstation-class Laptop" },
  { label: "Zero Client", value: "Zero Client" },
];

export const getCampusLabel = (value) => {
  const label = campusMap
    .filter((cm) => cm.value === value)
    .map((cm) => cm.label)
    .shift();
  return label || value;
};
export const isValidCampus = (value) => {
  return campusMap.filter((cm) => cm.value === value).length;
};
export const isValidDeviceType = (value) => {
  return deviceTypeMap.filter((dtm) => dtm.value === value).length;
};
