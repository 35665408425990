// React
import React from 'react';
import PropTypes from 'prop-types';

// Material
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';

// Third Party
import clsx from 'clsx';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 12,
    boxShadow: 'none',
  },
  hidden: {
    display: 'none'
  },
  iconPadding: {
    paddingLeft: 12,
    paddingRight: 12
  },
  iconSm: {
    fontSize: 16,
    paddingRight: 2
  },
  link: {
    color: '#165D79',
    cursor: 'pointer !important',
    fontWeight: 500,
  },
  table: {
    minWidth: 360,
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tdSm: {
    fontSize: '0.9rem',
  },
});

class EnhancedTable extends React.Component {

  desc = (a, b, orderBy) => {
    const aValue = typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
    const bValue = typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }

  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  // handleRequestSort = (property) => {
  //   const orderBy = property;
  //   let order = 'desc';

  //   if (this.state.orderBy === property && this.state.order === 'desc') {
  //     order = 'asc';
  //   }

  //   this.setState({ order, orderBy });
  // };

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  render() {
    const { classes, data, fontSize = "default", onRowClick, onSortClick, order, orderBy, rowHeaders, } = this.props;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {rowHeaders.map(row => {
                  return (
                    <TableCell
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === row.id ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement={row.align !== 'left' ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={() => onSortClick(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                }, this)}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.stableSort(data, this.getSorting(order, orderBy))
                .map((sd) => {
                  return (
                    <TableRow
                      hover={true}
                      tabIndex={-1}
                      key={sd.id}
                    >
                      {rowHeaders.map((rh) => {
                        let cellValue = sd[rh.id];

                        const className = clsx({
                          [classes.link]: rh.clickable,
                          [classes.tdSm]: fontSize === 'small',
                        });

                        return (<TableCell key={`cell_${sd.id}_${rh.id}`} className={className} onClick={() => rh.clickable ? onRowClick(sd.title, sd.version) : {}}>{cellValue}</TableCell>);
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowHeaders: PropTypes.array.isRequired,
};

export default withStyles(styles)(EnhancedTable);
