// React
import React from 'react';

// Material
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Styles for the Breadcrumbs Class
const styles = theme => ({
  root: {
    ...theme.foregroundAlt,
    ...theme.flex,
    ...theme.paddingTopSm,
    paddingBottom: 6,
    fontSize: '0.85rem',
    lineHeight: 1.66,
  },
  bold: theme.boldSm,
  flex: theme.flex,
  icon: {
    fontSize: '1.25rem',
    height: 20,
    marginRight: theme.spacing(0.5),
    width: 20,
  },
  paddingRight: theme.paddingRightXs,
});

export const getClasses = () => {
  return useStyles();
}

/**
 * Class Breadcrumbs takes props and returns a corresponding Breadcrumbs element
 * @implements {React.Component}
 */
class BreadcrumbsWrapper extends React.Component {

  render() {
    const { aria = 'breadcrumb', classes, filters, separator = '>', variant = 'caption' } = this.props;

    const elements = filters.filter(f => f !== undefined && f !== null).map(f => {
      if (Array.isArray(f)) {
        f = f.map((fo, idx) => ((idx === f.length - 1 ? fo.value : `${fo.value} OR `)));
      }
      return <Typography key={`breadcrumb_${f.value || f}`} variant={variant} className={classes.bold}>{f.value || f}</Typography>
    });

    return <div className={classes.flex}>
      <Typography className={`${classes.root} ${classes.paddingRight}`} variant={variant}>Filter:</Typography>
      <Breadcrumbs classes={{ root: classes.root }} separator={separator}>
        {elements}
      </Breadcrumbs>
    </div>;
  }
}

export default withStyles(styles)(BreadcrumbsWrapper);