
// React
import React from 'react';
import { Redirect, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

// Config
import { runtimeConfig } from '../../config/master';

// Pages
import LabPage from '../pages/Lab';
import SearchPage from '../pages/Search';

class Body extends React.Component {

  render() {
    const subpath = runtimeConfig.subpath || '/lab-lookup';
    return (
      <div>
        <div className="body-wrapper">
          <section className="content-wrapper">
            {/* <div style={{ padding: '1em', width: '100%' }}> */}
            <Switch>
              <Route exact={true} path={subpath} render={() => <SearchPage toggleLoading={this.props.toggleLoading} />} key={0} />
              <Route path={subpath.concat('/search')} render={() => <SearchPage toggleLoading={this.props.toggleLoading} />} key={1} />
              <Route path={subpath.concat('/lab/:room/:labName')} key={2} render={() => (<LabPage />)} />
              <Route path={subpath.concat('/lab/:room')} key={3} render={() => (<LabPage />)} />
              <Route path={subpath.concat('/lab')} key={4} render={() => (<LabPage />)} />
              <Route render={() => <SearchPage toggleLoading={this.props.toggleLoading} />} />
            </Switch>
            {/* </div> */}
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Body);
