
// React
import React from 'react';
import { withRouter } from 'react-router';

// Material
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

// Third Party
import Select from 'react-select';
import clsx from 'clsx';

// Config
import { runtimeConfig } from '../../config/master';

// Internal
import { apiCall } from '../atoms/Fetch';
import Spacer from '../atoms/Spacer';
import { getCurrentDateTime, getIcon, getOptionObject, handleSortClick, insertItem, compare, updateObjectInArray } from '../atoms/Util';
import EnhancedTable from '../organisms/EnhancedTable';
import { isValidDeviceType } from '../atoms/Lookup';

// class styling
const styles = theme => ({
  iconSm: {
    fontSize: 16,
    marginRight: 4,
    verticalAlign: 'middle',
  },
  marginBottomMd: theme.marginBottomMd,
  marginBottomLg: theme.marginBottomLg,
  paper: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  root: {
    margin: '0 auto',
    maxWidth: 800,
  },
  select: theme.select,
  subtext: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  td: {
    fontSize: '0.9rem',
  },
  title: {
    flex: '0 0 auto',
    // padding: theme.spacing(1),
  },
  toolbar: {
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
});

class LabPage extends React.Component {

  constructor(props) {
    super(props);
    const assignments = props.location.state ? props.location.state.assignments : undefined;

    const rowHeaders = [
      { align: 'left', id: 'title', disablePadding: false, label: 'Software Title', clickable: false },
      { align: 'left', id: 'version', disablePadding: false, label: 'Version', clickable: false },
      { align: 'left', id: 'installedDevices', disablePadding: false, label: 'Installed Devices', clickable: false, isArray: true },
    ];

    this.state = {
      assignments,
      filterOptions: [getOptionObject('All', 'all')],
      order: 'asc',
      orderBy: 'title',
      rowHeaders,
      selectedFilter: { label: 'All', value: 'all' },
    };
  }

  async componentDidMount() {
    const { classes } = this.props;
    let { assignments, filterOptions } = this.state;
    if (!assignments) {
      // get from db call
      const { room, labName } = this.props.match.params;
      const uri = `${runtimeConfig.apiUrl}/assn/get/multiple/${room}/${labName || ''}`;
      assignments = await apiCall(uri, 'GET');
    }

    // setup each lab
    assignments.forEach(a => {
      a.id = `${a.schoolYear}-${a.title}`;
      a.label = `${a.schoolYear} ${a.title}`;
      a.hardwares = a.hardwares.filter(h => h.status === 'install')
        .map(h => ({
          deviceNumber: h.deviceNumber,
          deviceType: h.deviceType,
          id: h.id ? h.id.toString() : h._id.toString(),
          status: h.status,
        })),
        a.softwares = a.softwares.filter(s => s.status === 'install')
          .map(s => ({
            hardwareIds: s.hardwareIds,
            id: `${s.title}-${s.version.value}`,
            title: s.title,
            version: s.version.value || s.version,
          }))
          .concat({
            hardwareIds: a.hardwares.filter(h => h.status === 'install' && isValidDeviceType(h.deviceType)).map(h => h.id),
            id: `Standard Software-Latest`,
            title: 'Standard Software',
            version: 'Latest'
          }),
        a.data = this.getData(a.softwares, a.hardwares, 'All', classes);
      a.filterOptions = this.getFilterOptions(a.hardwares, filterOptions);
      a.selectedFilter = a.filterOptions[0];
    });

    this.setState({ assignments });
  }

  getData = (softwares, hardwares, filter, classes) => {
    let rowHardwares = [];
    // map to data
    return softwares.filter(s => {
      // set the hardware rows
      rowHardwares = this.getHardwareRows(s.id, s.hardwareIds, hardwares, filter);
      // filter out the software if there are 0 hardwares
      return rowHardwares.length;
    }).map(s => {
      // set the hardware rows
      rowHardwares = this.getHardwareRows(s.id, s.hardwareIds, hardwares, filter);

      // get distinct hardware device types the software is installed on
      const deviceTypes =
        rowHardwares.map(h => h.deviceType).filter((elem, pos, arr) => arr.indexOf(elem) === pos);

      // last column in row
      let deviceTypeOutputs = [];
      // for each device type, get the device numbers from the respective hardwares
      deviceTypes.forEach((d, idx) => {
        // get the icon name based on the device type
        const iconName = getIcon(d);
        const deviceNumbers = rowHardwares.filter(h => h.sid === s.id && h.deviceType === d).map(h => h.deviceNumber).filter((elem, pos, arr) => arr.indexOf(elem) === pos);
        // array to string, replacing the commas with "|"
        const output = `${d}: ${deviceNumbers.toString().replace(/,/g, ' | ')}`;
        // create the icon + device type + device number output
        const iconOutput = [<Icon className={classes.iconSm}>{iconName}</Icon>, ` ${output}`];
        // add a line break if there are more device types
        const deviceTypeElement = idx < deviceTypes.length - 1 ? iconOutput.concat([<br />]) : iconOutput;
        // const deviceTypeElement = idx < deviceTypes.length - 1 ? [<Icon className={classes.iconSm}>{iconName}</Icon>, ` ${output}`, <br />] : [<Icon className={classes.iconSm}>{iconName}</Icon>, ` ${output}`];
        // append to the previous device type outputs
        deviceTypeOutputs = insertItem(deviceTypeOutputs, {
          index: deviceTypeOutputs.length,
          item: deviceTypeElement,
        });
      });
      // return row data
      return {
        title: s.title,
        version: s.version,
        installedDevices: deviceTypeOutputs
      };
    });
  };

  getFilterOptions = (hardwares, filterOptions) => {
    let deviceOptions = [];
    // distinct device types for the lab
    const deviceTypes = hardwares.map(h => h.deviceType).filter((elem, pos, arr) => arr.indexOf(elem) === pos);
    // add them
    deviceTypes.forEach(dt => {
      deviceOptions = insertItem(deviceOptions, {
        index: deviceOptions.length,
        item: getOptionObject(dt, dt.toLowerCase()),
      });
    });
    // sort them
    deviceOptions.map(d => (d.value)).sort((a, b) => compare(a, b, [{ value: 'value', desc: false }]));
    // append them and return them
    return filterOptions.concat(deviceOptions);
  };

  getHardwareRows(softwareId, hardwareIds, hardwares, filter) {
    let rh = [];
    // get all hardware the software is on
    hardwareIds.forEach(hid => {
      const hardware = filter === 'All' ? hardwares.filter(h => h.id === hid).shift()
        : hardwares.filter(h => h.deviceType === filter && h.id === hid).shift();
      // if there is a matching hardware id, then add it to the row's hardware
      rh = hardware ? insertItem(rh, {
        index: rh.length,
        item: {
          ...hardware,
          sid: softwareId,
        },
      })
        : rh;
    });
    return rh;
  }

  getLab = (assignment, finalLab = true) => {
    const { classes } = this.props;
    const { order, orderBy, rowHeaders } = this.state;

    if (assignment.data) {
      const labDiv = clsx(classes.root, {
        [classes.marginBottomLg]: !finalLab,
        [classes.marginBottomMd]: finalLab,
      });
      return (
        <div className={labDiv}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.title}>
              <Typography className={classes.subtext} variant="caption">
                {getCurrentDateTime()}
              </Typography>
              <Typography variant="h1" id="tableTitle">
                {assignment.label}
              </Typography>
            </div>
            <Spacer />
            <div className={classes.select}>
              <Typography
                className={classes.subtext}
                variant="caption"
                gutterBottom={true}>
                Filter by Device
              </Typography>
              <Select
                value={assignment.selectedFilter}
                onChange={(e) => this.handleFilterChange(e, assignment, classes)}
                options={assignment.filterOptions}
              />
            </div>
          </Toolbar>
          <EnhancedTable
            data={assignment.data}
            fontSize="small"
            onSortClick={this.handleSortClick}
            order={order}
            orderBy={orderBy}
            rowHeaders={rowHeaders}
          />
        </div>
      );
    } else {
      return [];
    }
  };

  handleFilterChange = (option, assignment, classes) => {
    let { assignments } = this.state;
    const { softwares, hardwares } = assignment;
    if (assignment.selectedFilter.value !== option.value) {
      // set selected filter
      assignment.selectedFilter = option;
      // set filtered data
      assignment.data = this.getData(softwares, hardwares, option.label, classes);

      // update assignments array
      const index = assignments.map(a => a.id).indexOf(assignment.id);
      assignments = index !== -1 ? updateObjectInArray(assignments, {
        index,
        item: assignment,
      })
        : assignments;

      // update state && UI
      this.setState({ assignments })
    }
  }

  handleSortClick = (property) => {
    const { order, orderBy } = this.state;
    this.setState(handleSortClick(property, order, orderBy));
  };

  render() {
    const { assignments } = this.state;
    if (assignments) {
      return assignments.map((a, idx) => this.getLab(a, idx === (assignments.length - 1)));
    } else {
      return [];
    }
  }
}

export default withRouter(withStyles(styles)(LabPage));
