// support ie11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// React && Redux
import React from 'react';
import { hydrate } from 'react-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';

// Container
import LabLookup from './components/templates/LabLookup';

// create a history object for the client
const history = createBrowserHistory();

// re-hydrate to use server HTML rather than generate DOM
hydrate(
  <Router history={history}>
    <LabLookup />
  </Router>,
  document.getElementById('root')
);

// enable hot swapping of modules to view changes live
if (module.hot) {
  module.hot.accept('./components/templates/LabLookup', () => {
    hydrate(
      <Router history={history}>
        <LabLookup />
      </Router>,
      document.getElementById('root')
    )
  });
}
