export const apiCall = async (uri, action, metadata) => {
  try {
  return fetch(uri, {
    body: metadata ? JSON.stringify(metadata) : undefined,
    headers: {
      'Content-Type': 'application/json',
    },
    method: action,
    mode: 'cors', // no-cors, cors, *same-origin
  })
    .then((res) => res.json())
    .catch((e) => {
      console.error(e);
      return e;
    });
  } catch (e) {
    console.error(e);
    return e.json() || e;
  }
};
