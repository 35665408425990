export const compare = (a, b, keys) => {
  let sortNum = 0;
  keys.forEach(key => {
    const aValue = typeof a[key.value] === "string" ? a[key.value].toLowerCase() : a[key.value];
    const bValue = typeof b[key.value] === "string" ? b[key.value].toLowerCase() : b[key.value];

    if (aValue < bValue) {
      sortNum = key.desc ? 1 : -1;
    }
    if (aValue > bValue) {
      sortNum = key.desc ? -1 : 1;
    }
  });

  return sortNum;
};

export const getCurrentSchoolYear = (schoolYears) => {
  const curDate = new Date();
  const thisYear = `${(curDate.getFullYear() - 1).toString()}-${curDate.getFullYear().toString()}`;
  const nextYear = `${curDate.getFullYear().toString()}-${(curDate.getFullYear() + 1).toString()}`;

  if ((curDate.getMonth() + 1) > 5 || (schoolYears.length && schoolYears.filter(s => s.value === nextYear).length)) {
    return nextYear;
  }
  return thisYear;
};

export const filterChange = (searchTerms, data, filterKeys) => {
  if (searchTerms && searchTerms.length) {
    let termFound = false;
    const sourceData = data;
    // filter data that matches any of the search terms
    const filteredData = sourceData.filter((sd) => {
      // iterate through search terms to see if we need to include the assn in the filter
      const includeItem = searchTerms.filter((term) => {
        termFound = false;
        // check if item's filter keys fuzzy match any terms
        filterKeys.forEach(key => {
          if (!termFound) {
            if (sd.hasOwnProperty(key.name) && sd[key.name]) {
              termFound =
                key.isArray ?
                  sd[key.name].toString().toLowerCase().includes(term) :
                  sd[key.name].toString().toLowerCase().includes(term);
            }
          }
        });
        return termFound;
      }).length === searchTerms.length;

      return includeItem;
    });

    return { data: filteredData };
  } else {
    return { data };
  }
};

export const handleSortClick = (property, curOrder, curOrderBy) => {
  const orderBy = property;
  let order = 'asc';

  if (curOrderBy === property && curOrder === 'asc') {
    order = 'desc';
  }

  return { order, orderBy };
};

export function getCurrentDateTime() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const moddedHours = date.getHours() % 12;
  const hour = moddedHours === 0 ? 12 : moddedHours;
  const minute = date.getMinutes();
  const ampm = date.getHours() > 11 ? 'PM' : 'AM';

  let dtOutput = '';

  dtOutput = month < 10 ? `0${month.toString()}` : month.toString();
  dtOutput += day < 10 ? `/0${day.toString()}` : `/${day.toString()}`;
  dtOutput += `/${year} ${hour}:${minute} ${ampm}`;

  return dtOutput;
}

export function getIcon(deviceType = '') {
  let icon = 'desktop_windows';
  switch (deviceType.toLowerCase()) {
    case 'desktop':
    case 'workstation-class desktop':
      break;
    case 'laptop':
    case 'workstation-class laptop':
      icon = 'laptop_windows';
      break;
    case 'macbook':
    case 'imac or macpro':
      icon = 'laptop_mac';
      break;
    case 'windows tablet':
      icon = 'tablet';
      break;
    case 'ios device':
      icon = 'tablet_mac';
      break;
    case 'mondopad-pc':
    case 'aquos board-pc':
      icon = 'tv';
      break;
  }
  return icon;
};

export const getOptionObject = (label, value) => {
  return { label, value };
}

export const insertItem = (array, action) => {
  return [
    ...array.slice(0, action.index),
    action.item,
    ...array.slice(action.index)
  ];
};

export const updateObjectInArray = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item
    };
  });
};