// React && Redux
import React from "react";
// import { withRouter } from 'react-router';

// Material
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// Internal
import Spacer from "../atoms/Spacer";

// Config
import { runtimeConfig } from "../../config/master";

// Images
import primaryLogo from "../../../public/images/mptc-logo.svg";
import secondaryLogo from "../../../public/images/mptc-student-logo.svg";

// class styling
const styles = (theme) => ({
  appBar: {
    backgroundColor: "#f0f0f0 !important",
    borderBottom: "3px solid #5bb84d",
  },
  primaryLogo: {
    ...theme.appLogo,
    marginRight: 24,
    minWidth: 100,
    maxWidth: 193,
    width: "100%",
  },
  pointer: theme.pointer,
  secondaryLogo: {
    ...theme.appLogo,
    marginLeft: 24,
    minWidth: 100,
    maxWidth: 193,
    width: "100%",
  },
  toolbarWrapper: {
    backgroundColor: "#f0f0f0",
    color: "#333333",
    display: "flex",
    margin: "0 auto",
    minWidth: 360,
    width: "100%",
  },
});

class Header extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className="header-wrapper">
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolbarWrapper}>
            {/* tslint:disable-next-line:max-line-length */}
            <a
              href="https://www.morainepark.edu"
              className={classes.primaryLogo}
            >
              <img
                src={runtimeConfig.publicPath.concat(primaryLogo)}
                className={classes.primaryLogo}
                alt="MPTC logo and link"
              />
            </a>
            <Spacer />
            <Typography style={{ minWidth: 120 }} variant="h1" color="inherit">
              Lab Lookup
            </Typography>
            <Spacer />
            <a
              href="https://student.morainepark.edu"
              className={classes.primaryLogo}
            >
              <img
                src={runtimeConfig.publicPath.concat(secondaryLogo)}
                className={classes.secondaryLogo}
                alt="myMPTC Student logo and link"
              />
            </a>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(Header);
