// REACT
import React from 'react';

// SPACER CLASS
class Spacer extends React.Component {
  render() {
    return (
      <span style={{ flex: 1 }}></span>
    );
  }
}

export default Spacer;