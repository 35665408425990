
// React
import React from 'react';
import { withRouter } from 'react-router';

// Config
import { runtimeConfig } from '../../config/master';

// Internal
import { apiCall } from '../atoms/Fetch';
import { getCampusLabel, isValidCampus, isValidDeviceType, } from '../atoms/Lookup';
import { compare, getOptionObject, handleSortClick, insertItem, updateObjectInArray } from '../atoms/Util';
import EnhancedTable from '../organisms/EnhancedTable';
import SearchDialog from '../organisms/SearchDialog';
import SearchFilter from '../organisms/SearchFilter';
import StandardSoftwareDialog from '../organisms/StandardSoftwareDialog';

class SearchPage extends React.Component {

  constructor(props) {
    super(props);
    const rowHeaders = [
      { align: 'left', id: 'title', disablePadding: false, label: 'Software Title', clickable: true },
      { align: 'left', id: 'version', disablePadding: false, label: 'Version', clickable: true },
      { align: 'left', id: 'numLabs', disablePadding: false, label: 'No. Labs', clickable: true },
    ];
    this.state = {
      assignments: [],
      data: [],
      filters: [],
      listItems: [],
      options: [],
      order: 'asc',
      orderBy: 'title',
      rowHeaders,
      searchDialogOpen: false,
      standardDialogOpen: false,
    };
  }

  async componentDidMount() {
    this.props.toggleLoading(true);
    const assignments = await this.getAssignments();
    const options = this.getOptions(assignments);
    this.setState({ assignments, data: options.softwares, filteredOptions: options, options }, () => {
      this.props.toggleLoading(false);
    });
  }

  async getAssignments() {
    const uri = `${runtimeConfig.apiUrl}/assn/get/all`;
    const assignments = await apiCall(uri, 'GET');
    return assignments ? assignments.filter(a => !a.internalOnly).map(a => {
      return ({
        active: a.active,
        altId: a._id.toString(),
        campus: a.campus,
        hardwares: a.hardwares.filter(h => h.status === 'install')
          .map(h => ({
            deviceNumber: h.deviceNumber,
            deviceType: h.deviceType,
            id: h._id.toString(),
            label: `${h.deviceType} - ${h.deviceNumber}`,
            status: h.status,
          })),
        id: `${a.schoolYear}-${a.title}`,
        lab: a.lab,
        label: `${a.schoolYear} ${a.title}`,
        room: a.room,
        schoolYear: a.schoolYear,
        softwares: a.softwares.filter(s => s.status === 'install' && s.hardwareIds && s.hardwareIds.length)
          .map(s => ({
            hardwareIds: s.hardwareIds,
            id: `${s.title}-${s.version.value}`,
            label: `${s.title} - ${s.version.value}`,
            numLabs: assignments.filter(a =>
              a.softwares
                .filter(s2 =>
                  s2.status === 'install' &&
                  s2.hardwareIds &&
                  s2.hardwareIds.length &&
                  s2.title === s.title &&
                  s2.version.value === s.version.value
                ).length
            ).length,
            status: s.status,
            title: s.title,
            version: s.version.value,
          })),
        title: a.title,
      });
    })
      : [];
  }

  getFilteredData(data, filters) {
    return data.filter(d => {
      // const software = this.getSoftwareMetadata(d.title, d.version);

      // only use software rows where the associated assignment detail matches the filters
      return d.filteredAssignments.filter(fa => {
        let includeRow = true;
        filters.forEach(f => {
          // is this assignment still included and the filter not empty?
          if (includeRow) {
            // is this a multi-select filter?
            // if (Array.isArray(f.option)) {
            if (f.name === 'deviceType') {
              // filter through the assignment hardware that contains the software and match one of the device types
              includeRow = f.option.filter(o => fa.hardwares.filter(h => h.hasSoftware && h.hasSoftware.includes(`${d.title} - ${d.version}`) && h.deviceType === o.value).length).length;
            } else {
              // otherwise make sure the assignment matches at least one of the selected filters
              includeRow = f.option.filter(o => fa[f.name] === o.value).length;
            }
            // } else {
            //   // no array, no cry
            //   includeRow = fa[f.name] === f.option.value;
            // }
          }
        });
        return includeRow;
      }).length;
    });
  }

  getFilteredOptions(options, filters, assignments) {
    let { rooms = [], labs = [], deviceTypes = [] } = options;
    const { softwares } = options;

    filters.forEach(f => {
      switch (f.name) {
        case 'campus':
          rooms = this.getMatchingOptions(softwares, f, null, assignments);
          break;
        case 'room':
          labs = this.getMatchingOptions(softwares, f, null, assignments);
          break;
        case 'lab':
          deviceTypes = this.getMatchingOptions(softwares, filters, 'deviceType', assignments);
          break;
      }
    });
    return { ...options, rooms, labs, deviceTypes };
  }

  getListItemIndex = (lab) => {
    return this.state.listItems.map(lo => lo.lab).indexOf(lab);
  };

  getListItems(assignments) {
    let listItems = [];
    for (const a of assignments) {
      listItems = insertItem(listItems, {
        index: listItems.length,
        item: { lab: a.label, open: false }
      });
    }
    return listItems;
  }

  getMatchingOptions(softwares, filter, optionName, assignments) {
    if (!Array.isArray(filter)) {
      return softwares.flatMap(s =>
        s.filteredAssignments
          .filter(fa => filter.option.filter(o => fa[filter.name] === o.value).length)
          .map(fa => getOptionObject(fa[filter.optionName], fa[filter.optionName])))
        .concat(
          assignments
            .filter(a => filter.option.filter(o => a[filter.name] === o.value).length)
            .map(a => getOptionObject(a[filter.optionName], a[filter.optionName]))
        )
        .filter((elem, pos, arr) => arr.map(a => a.value).indexOf(elem.value) === pos)
        .sort((a, b) => compare(a, b, [{ value: "value", desc: false }]));
    } else {
      return softwares.flatMap(s =>
        s.filteredAssignments
          .filter(fa => {
            let includeRow = true;
            filter.forEach(f => {
              if (includeRow && f.name !== 'deviceType') {
                includeRow = f.option.filter(o => fa[f.name] === o.value).length;
              }
            });
            return includeRow;
          })
          .flatMap(fa =>
            fa.hardwares
              .filter(h => h.hasSoftware && h.hasSoftware.includes(`${s.title} - ${s.version}`))
              .map(h => getOptionObject(h[optionName], h[optionName]))))
        .concat(
          assignments
            .filter(a => {
              let includeRow = true;
              filter.forEach(f => {
                if (includeRow && f.name !== 'deviceType') {
                  includeRow = f.option.filter(o => a[f.name] === o.value).length;
                }
              });
              return includeRow;
            })
            .flatMap(a =>
              a.hardwares.filter(h => isValidDeviceType(h.deviceType)).map(h => getOptionObject(h[optionName], h[optionName]))
            )
        )
        .filter((elem, pos, arr) => arr.map(a => a.value).indexOf(elem.value) === pos)
        .sort((a, b) => compare(a, b, [{ value: "value", desc: false }]));
    }
  }

  getOptions(assignments) {
    if (assignments && assignments.length) {
      let softwareSeen = new Set();
      let deviceTypeSeen = new Set();
      const schoolYears = assignments.map(a => getOptionObject(a.schoolYear, a.schoolYear))
        .filter((elem, pos, arr) => arr.map(a => a.value).indexOf(elem.value) === pos)
        .sort((a, b) => compare(a, b, [{ value: 'label', desc: false }]));
      const campuses = assignments.map(a => getOptionObject(getCampusLabel(a.campus), a.campus))
        .filter((elem, pos, arr) => isValidCampus(elem.value) && arr.map(a => a.value).indexOf(elem.value) === pos)
        .sort((a, b) => compare(a, b, [{ value: 'label', desc: false }]));
      const rooms = assignments.map(a => getOptionObject(a.room, a.room))
        .filter((elem, pos, arr) => arr.map(a => a.value).indexOf(elem.value) === pos)
        .sort((a, b) => compare(a, b, [{ value: 'label', desc: false }]));
      const labs = assignments.map(a => getOptionObject(a.lab, a.lab))
        .filter((elem, pos, arr) => arr.map(a => a.value).indexOf(elem.value) === pos)
        .sort((a, b) => compare(a, b, [{ value: 'label', desc: false }]));
      const deviceTypes = assignments.flatMap(a =>
        a.hardwares.filter(h => {
          let keep = false;
          if (isValidDeviceType(h.deviceType) && !deviceTypeSeen.has(h.deviceType)) {
            deviceTypeSeen.add(h.deviceType);
            keep = true;
          }
          return keep;
        })
          .map(h => getOptionObject(h.deviceType, h.deviceType))
        // .map(h => getOptionObject(getDeviceTypeLabel(h.deviceType), h.deviceType))
        // .filter((elem, pos, arr) => arr.map(a => a.value).indexOf(elem.value) === pos)
      ).sort((a, b) => compare(a, b, [{ value: 'label', desc: false }]));
      const softwares = assignments.flatMap(a =>
        a.softwares.filter(s => {
          let keep = false;
          if (!softwareSeen.has(s.label)) {
            softwareSeen.add(s.label);
            keep = true;
          }
          return keep;
        })
          .map(s => {
            const meta = this.getSoftwareMetadata(s.title, s.version, assignments);
            return {
              ...s, filteredAssignments: meta.filteredAssignments
            }
          }));
      softwares.sort((a, b) =>
        compare(a, b, [{ value: 'label', desc: false }]));
      return {
        schoolYears,
        campuses,
        rooms,
        labs,
        deviceTypes,
        softwares,
      };
    }
    return [];
  }

  getSoftwareMetadata = (title, version, prestateAssignments) => {
    const assignments = prestateAssignments || this.state.assignments;
    const filteredAssignments =
      assignments
        .filter(a => a.softwares.map(s => s.label).includes(`${title} - ${version}`))
        .map(a => {
          const filteredSoftware = a.softwares.filter(s => s.label === `${title} - ${version}`).shift();
          filteredSoftware.hardwareIds
            .forEach(hid => {
              const index = a.hardwares.map(h => h.id).indexOf(hid);
              a.hardwares = index !== -1 ?

                updateObjectInArray(a.hardwares, {
                  index,
                  item: {
                    ...a.hardwares[index],
                    hasSoftware: a.hardwares[index].hasSoftware && a.hardwares[index].hasSoftware.length ?
                      insertItem(a.hardwares[index].hasSoftware, {
                        index: a.hardwares[index].hasSoftware.length,
                        item: `${title} - ${version}`
                      }) : [`${title} - ${version}`],
                  }
                })
                : a.hardwares;
            });
          return a;
        });
    return {
      label: `${title} - ${version}`,
      title,
      version,
      filteredAssignments
    };
  };

  handleDialogToggle = (dialogName) => {
    if (dialogName) {
      this.setState({ [dialogName]: !this.state[dialogName] });
    }
  };

  handleFilterClear = (filterName) => {
    const { filters } = this.state;
    let newFilters = [];
    if (filterName) {
      switch (filterName) {
        case 'room':
          newFilters = filters.filter(f => f.name !== filterName && f.name !== 'lab' && f.name !== 'deviceType');
          break;
        case 'lab':
          newFilters = filters.filter(f => f.name !== filterName && f.name !== 'deviceType');
          break;
        case 'deviceType':
          newFilters = filters.filter(f => f.name !== filterName);
          break;
      }
      return newFilters;
    } else {
      // this.setState({ data: this.state.softwares, filters: [] });
      return [];
    }
  };

  handleFilterChange = (name, optionName, option) => {
    const { softwares } = this.state.options;
    const index = this.state.filters.map(f => f.name).indexOf(name);

    const filters = option && option.length && index === -1
      ? insertItem(this.state.filters, {
        index: this.state.filters.length,
        item: { name, optionName, option }
      })
      : option && option.length
        ? updateObjectInArray(this.state.filters, {
          index,
          item: { name, optionName, option }
        })
        : this.handleFilterClear(name);

    const filteredData = this.getFilteredData(softwares, filters);
    const filteredOptions = this.getFilteredOptions(this.state.options, filters, this.state.assignments);
    // this.setState({ data: filteredData, filters });
    this.setState({ data: filteredData, filters, filteredOptions, });
  };

  handleRowClick = (title, version) => {
    // set selected software
    const selectedSoftware = this.getSoftwareMetadata(title, version);
    const listItems = this.getListItems(selectedSoftware.filteredAssignments);
    this.setState({ searchDialogOpen: true, listItems, selectedSoftware });
  };

  handleSortClick = (property) => {
    const { order, orderBy } = this.state;
    this.setState(handleSortClick(property, order, orderBy));
  };

  handleToggleClick = (lab) => {
    let { listItems } = this.state;
    const index = this.getListItemIndex(lab);

    listItems = updateObjectInArray(listItems, {
      index,
      item: {
        lab,
        open: !listItems[index].open,
      },
    });

    this.setState({ listItems });
  };

  render() {
    const { assignments, data, searchDialogOpen, filteredOptions = [], isLoading, listItems,
      order, orderBy, rowHeaders, selectedSoftware, standardDialogOpen } = this.state;
    return (
      <section>
        {filteredOptions.campuses ? <SearchFilter
          assignments={assignments}
          onDialogClick={() => this.handleDialogToggle('standardDialogOpen')}
          onFilterChange={this.handleFilterChange}
          options={filteredOptions}
          noSoftware={data.length}
        /> : null
        }
        <div>
          {/* <div style={{ padding: '0 24px 24px' }}> */}
          <EnhancedTable
            classes={{}}
            data={data}
            // onRequestSort={handleRequestSort(id)}
            onRowClick={this.handleRowClick}
            onSortClick={this.handleSortClick}
            order={order}
            orderBy={orderBy}
            rowHeaders={rowHeaders}
          />
        </div>
        <SearchDialog
          listItems={listItems}
          onClose={() => this.handleDialogToggle('searchDialogOpen')}
          onToggleClick={this.handleToggleClick}
          open={searchDialogOpen}
          software={selectedSoftware}
        />
        <StandardSoftwareDialog
          onClose={() => this.handleDialogToggle('standardDialogOpen')}
          open={standardDialogOpen}
        />
      </section>
    );
  }
}

export default withRouter(SearchPage);
