// React && Redux
import React from 'react';
import { withRouter } from 'react-router';
// import { Link } from 'react-router-dom';

// Material
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

// Config
import { runtimeConfig } from '../../config/master';

// Internal
import { DialogTitle } from '../atoms/DialogTitle';
import { getIcon, updateObjectInArray } from '../atoms/Util';

// class styling
const styles = theme => ({
  appBar: {
    backgroundColor: '#f0f0f0 !important',
    borderBottom: '3px solid #5bb84d',
  },
  flex: {
    ...theme.flex,
    paddingRight: 8,
  },
  logo: {
    ...theme.appLogo,
    marginRight: 36,
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  pointer: theme.pointer,
  toolbarWrapper: {
    backgroundColor: '#f0f0f0',
    color: '#333333',
    display: 'flex',
    margin: '0 auto',
    minWidth: 360,
    width: '100%',
  },
});

class SearchDialog extends React.Component {

  getOpen = (lab) => {
    return this.props.listItems.filter(lo => lo.lab === lab).shift().open;
  }

  handlePrintClick = (assignment) => {
    // open lab in new tab
    const subpath = runtimeConfig.subpath || '';
    const assignments = [assignment];
    this.props.history.push(subpath.concat(`/lab/${assignment.room}/${assignment.lab}`), { assignments });
  }

  render() {
    const { classes, onClose, onToggleClick, open, software } = this.props;

    if (software) {

      return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="simple-dialog-title" onClose={onClose}>{software.label}</DialogTitle>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Labs
              </ListSubheader>
            }
          >
            {
              software.filteredAssignments.map(fa => {
                const listItemParent =
                  <div key={`wrapper-${fa.id}`} className={classes.flex}>
                    <ListItem key={`list-item-${fa.id}`} button onClick={() => onToggleClick(fa.label)}>
                      <ListItemIcon>
                        <Icon>devices_other</Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.paddingRight}
                        primaryTypographyProps={{ variant: 'body2' }}
                        primary={fa.label}
                      />
                      {this.getOpen(fa.label) ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                    </ListItem>
                    <IconButton aria-label="print lab" onClick={() => this.handlePrintClick(fa)}>
                      <Icon>print</Icon>
                    </IconButton>
                  </div>;
                const listItemChild = fa.hardwares
                  .filter(h => h.hasSoftware && h.hasSoftware.length && h.hasSoftware.includes(`${software.title} - ${software.version}`))
                  .map(h => (
                    <ListItem key={`${fa.id}-${h.deviceType}-${h.deviceNumber.toString()}`} button className={classes.nested}>
                      <ListItemIcon>
                        <Icon fontSize="small">{getIcon(h.deviceType)}</Icon>
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary={h.label} />
                    </ListItem>
                  ));
                const collapse =
                  <Collapse key={`collapse-${fa.id}`} in={this.getOpen(fa.label)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>{listItemChild}</List>
                  </Collapse>;
                return ([listItemParent, collapse]);
              })
            }
          </List>
        </Dialog>
      );
    } else {
      return [];
    }
  }
}

export default withStyles(styles)(withRouter(SearchDialog));
