// React && Redux
import React from 'react';

// Styles
// import '../../styles/LabUpgrades.css';

// import { connect, Dispatch } from 'react-redux';
import { withRouter } from 'react-router';

// Material
import { ThemeProvider, withStyles, } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// Images
import loading from '../../../public/images/loading.svg';

// Internal
import theme from '../atoms/Theme';
import Header from '../organisms/Header';
import Body from '../organisms/Body';

const styles = () => ({
  root: {
    display: 'flex',
  },
  hidden: {
    display: 'none'
  },
  loading: {
    backgroundColor: 'rgb(255, 255, 255, 0.8)',
    bottom: 0,
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100vw',
    zIndex: 9999999,
  },
  loadingImage: {
    width: '25%',
  },
});

class LabLookup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  toggleLoading = (isLoading = false) => {
    this.setState({ isLoading });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className={{ root: { display: 'flex' } }}>
          <CssBaseline />
          <Header />
          <Body toggleLoading={this.toggleLoading} />
          <div className={this.state.isLoading ? this.props.classes.loading : this.props.classes.hidden}>
            <img className={this.props.classes.loadingImage} src={loading} alt="loading..." />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withRouter(withStyles(styles)(LabLookup));
