// React && Redux
import React from 'react';
// import { withRouter } from 'react-router';
// import { Link } from 'react-router-dom';

// Material
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

// Config
import { runtimeConfig } from '../../config/master';

// Internal
import { DialogTitle } from '../atoms/DialogTitle';
import { getIcon, updateObjectInArray } from '../atoms/Util';
import { software } from '../atoms/StandardSoftware';

// class styling
const styles = theme => ({
  dialog: {
    minWidth: 300,
  },
});

class StandardSoftwareDialog extends React.Component {

  render() {
    const { classes, onClose, open } = this.props;
    return (
      <Dialog className={classes.dialog} onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title" onClose={onClose}>Standard Software</DialogTitle>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Available in All Labs
          </ListSubheader>
          }
        >
          {
            software.map(s => {
              return <ListItem key={`list-item-${s}`}>
                <ListItemIcon>
                  <Icon>code</Icon>
                </ListItemIcon>
                <ListItemText
                  // className={classes.paddingRight}
                  primaryTypographyProps={{ variant: 'body2' }}
                  primary={s}
                />
              </ListItem>;
            })
          }
        </List>
      </Dialog>
    );
  }
}

export default withStyles(styles)(StandardSoftwareDialog);
