export const software = [
  'Adobe Pro DC',
  'Google Chrome',
  'Microsoft Edge',
  'Microsoft Teams',
  'Mozilla Firefox',
  'Office Suite 2021',
  'VLC Media Player',
  'VMWare View Client',
  'Windows 11'
];
